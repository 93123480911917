export const data = [
	{
		to: '/',
		text: 'Tentang Kami',
		id: 'about',
		otherPage:false,
	},
	{
		to: 'https://moc.demo.mandiribelajar.co.id',
		text: 'MOC',
		otherPage:true,
	},
	{
		to: 'https://sip-mbkm.mandiribelajar.co.id',
		text: 'SIP-MBKM',
		otherPage:true,
	},
	{
		to: '/pricing',
		text: 'Pricing',
		otherPage:false,
	},
	{
		to: '/cobagratis',
		text: 'Coba Gratis',
		otherPage:false,
	}
];