import React from 'react'
import Footer from '../components/LandingPage/Footer/Footer'
import Pricing from '../components/LandingPage/Pricing/Pricing'
function PricingPages() {
    return (
        <>
            <Pricing />
            <Footer />
        </>
    )
}

export default PricingPages
