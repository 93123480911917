export const heroOne = {
  reverse: true,
  inverse: true,
  topLine: {
    text: 'Founded in Juli 2021',
  },
  headline: 'Meningkatkan pendidikan berbasis resource sharing',
  description:
    'Program ini berorientasi untuk meningkatkan mutu Pendidikan berbasis resource sharing antar Perguruan Tinggi. Program ini dibangun untuk meningkatkan mutu Pendidikan Tinggi berdasar semangat berbagi dan kebersamaan antar Perguruan Tinggi. Program layanan ini akan membantu para Perguruan Tinggi dalam pengelolaan pembelajaran terbuka berbasis web dan mobile, sehingga dapat diakses dimanapun dengan dukungan perangkat IT yang handal. Berbekal dukungan dari tim yang professional dalam pengelolaan perguruan tinggi berbasis Teknologi Informasi, sistem pembelajaran ini akan membantu  PT lebih efektif dan efisien dalam pengelolaan pembelajaran.',
  buttonLabel: 'Coba gratis',
  linkTo: '/cobagratis',
  imgStart: 'start',
  img: './assets/svg/Deal.svg',
  start: 'true',
}

export const heroTwo = {
  reverse: false,
  inverse: false,
  topLine: {
    text: 'Learning Quality',
  },
  headline: 'Penyediaan bahan ajar yang bermutu',
  description:
    ' Bahan ajar yang berkualitas bersumber dari buku ber-ISBN baik nasional maupun internasional serta disajikan dalam bentuk bahan siap ajar yang terdiri atas modul, ppt, video, tugas, quiz, link journal, soal UTS dan UAS. Penyusun bahan ajar adalah tim professional dari berbagai perguruan tinggi (PTN dan PTS) yang memiliki keahlian sesuai dengan bidangnya serta memiliki semangat berbagi untuk meningkatkan mutu Perguruan Tinggi',
  buttonLabel: 'Coba gratis',
  linkTo: '/cobagratis',
  imgStart: 'start',
  img: './assets/svg/Connection.svg',
  start: 'true',
}

export const heroThree = {
  reverse: true,
  inverse: true,
  topLine: {
    text: 'exchange of courses between universities',
  },
  headline: 'Pertukaran mata kuliah antar Universitas',
  description:
    'Perguruan Tinggi saling menawarkan mata kuliah keunggulan dan kekhasan untuk dapat diakses oleh Perguruan Tinggi lain. Sistem informasi pembelajaran dari Mandiri Belajar didesain untuk dapat terintegrasi antar Perguruan Tinggi, sehingga proses pembelajaran mulai dari KRS, manajemen kelas, nilai akhir mahasiswa dan proses pembayaran dapat dilakukan dengan realtime, efisien dan efektif',
  buttonLabel: 'Coba gratis',
  linkTo: '/cobagratis',
  imgStart: '',
  img: './assets/svg/ChartUp.svg',
  start: 'true',
}
